import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: "/Auth",
    name: "AuthView",
    component: () => import("@/Layout/AuthView.vue")
  },
  {
    path: "/Test",
    component: () => import("@/test/test1.vue")
  },
  {
    path: "/Meter",
    component: () => import("@/Debug/Meter.vue")
  },
  {
    path: "/CTL222",
    component: () => import("@/Debug/CTL222.vue")
  },
  {
    path: "/TXCAI221",
    component: () => import("@/Debug/TXCAI221_2.vue")
  },
  {
    path: '/',
    component: () => import('@/Layout/Layout'),
    children: [
      {
        path: 'Home',
        name: 'Home',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
      },
      {
        path: 'Dashboard/:Page',
        name: 'DashboardView',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/DashboardView.vue')
      },
      {
        path: 'Device',
        name: 'DeviceView',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/DeviceView.vue')
      },
      {
        path: 'PreAnalysis/:Page',
        name: 'PreAnalysis',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/DashboardView.vue')
      },
      {
        path: 'Analysis/:Page',
        name: 'AnalysisView',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AnalysisView2.vue')
      },
      {
        path: 'AI',
        name: 'AIView',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AIView.vue')
      },
    ]
  },
  
  /*{
    path: '/custom',
    name: 'custom',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.vue 
    component: () => import('../views/custom.vue')
  }*/
]

const router = createRouter({
  history: createWebHistory(),
  // base: process.env.BASE_URL,
  /*scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },*/
  routes
})

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start(800);
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router
