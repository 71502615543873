/*export default {
    pad2(d = 0) {
        return (d < 10)? "0" + d.toString():d.toString();
    },
    getDate(ts = null) {
        let now;
        if (ts == null) {
            now = new Date();
        }else {
            now = new Date(ts);
        } 
        let year = now.getFullYear().toString();
        let month = pad2(now.getMonth() + 1);
        let date = pad2(now.getDate());
        return "" + year + "-" + month + "-" + date;
    },
    paramDate(ts = null) {
        let now;
        if (ts == null) {
            now = new Date();
        }else {
            now = new Date(ts);
        } 
        let year = now.getFullYear().toString();
        let month = pad2(now.getMonth() + 1);
        let date = pad2(now.getDate());
        let hour = pad2(now.getHours());
        let minute = pad2(now.getMinutes());
        let sec = pad2(now.getSeconds());
        return "" + year + "/" + month + "/" + date + " " + hour + ":" + minute + ":" + sec
    },
    convertDateToConpare(date = "") {
        return Number(date.split("-").join(""));
    },
}*/
export function pad2(d = 0) {
    return (d < 10)? "0" + d.toString():d.toString();
}
export function getDate(ts = null) {
    let now;
    if (ts == null) {
        now = new Date();
    }else {
        now = new Date(ts);
    } 
    let year = now.getFullYear().toString();
    let month = pad2(now.getMonth() + 1);
    let date = pad2(now.getDate());
    return "" + year + "-" + month + "-" + date;
}
export function paramDate(ts = null) {
    let now;
    if (ts == null) {
        now = new Date();
    }else {
        now = new Date(ts);
    } 
    let year = now.getFullYear().toString();
    let month = pad2(now.getMonth() + 1);
    let date = pad2(now.getDate());
    let hour = pad2(now.getHours());
    let minute = pad2(now.getMinutes());
    let sec = pad2(now.getSeconds());
    return "" + year + "/" + month + "/" + date + " " + hour + ":" + minute + ":" + sec
}
export function convertDateToConpare(date = "") {
    return Number(date.split("-").join(""));
}