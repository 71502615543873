<template>
  <v-app>
    <router-view/>
  </v-app>
</template>

<script>
import firebase from 'firebase';
import { mapActions } from 'vuex';
mapActions
export default {
  name: 'App',
  beforeCreate() {
    firebase.auth().onAuthStateChanged((user) => {
      let MainPath = this.$route.fullPath.split("/")[1];
      if (!user) {
        if (
          MainPath == "" ||
          MainPath == "Dashboard" || 
          MainPath == "Device" || 
          MainPath == "PreAnalysis" || 
          MainPath == "Analysis" || 
          MainPath == "AI"
        ) {
          this.clearDataUser();
          this.$router.replace("/Auth");
        }
      }else {
        if (MainPath == "Auth") {
          this.$router.replace("/");
        }
        this.getDataUser(user.uid);
      }
    })
  },
  data: () => ({
    //
  }),
  methods: {
    ...mapActions(["clearDataUser", "getDataUser"]),
  }
}
</script>
